import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import App, { Container } from 'next/app';
import { withRouter } from 'next/router';
import Head from 'next/head';
import { connectWithRedux } from '@goodfood/store';
import { Auth0Provider } from 'use-auth0-hooks';
import { getconfig } from '@goodfood/utils';
import Router from 'next/router';
import { initI18n } from '@goodfood/lib';
require('../styles/custom.scss');

const config = getconfig()

const onRedirectCallback = appState => {
    if (appState && appState.returnTo) {
        const {asPath, pathname, query} = appState.returnTo
        return asPath ? Router.push(asPath) : Router.push({pathname,query})
    }
};

const onRedirecting = () => {
    return (
        <div>
            loading...
        </div>
    );
};

interface Props {
    store: any;
}

class NextApp extends App<Props> {
    static async getInitialProps({ Component, ctx }: any) {
        const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
        return { pageProps };
    }

    constructor(props: any) {
        super(props);
        initI18n();
    }
    componentDidMount(){
        if (typeof window !== 'undefined') {
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2102637,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window as any,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        }
    }

    render() {
        const { Component, pageProps, store }: any = this.props;

        return (
            <React.Fragment>
                <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
                </Head>
                <>
                    {/* TODO : Use env variables */}
                    <Auth0Provider
                        domain={config.authDomain}
                        clientId={config.clientId}
                        redirectUri={config.redirectUri}
                        cacheLocation="localstorage"
                        audience={config.audience}
                        scope=''
                        onRedirecting={onRedirecting}
                        onRedirectCallback={onRedirectCallback}
                    >
                        <Provider store={store}>
                                <Component {...pageProps} />
                        </Provider>
                    </Auth0Provider>
                </>
            </React.Fragment>
        );
    }
}

export default connectWithRedux(withRouter(NextApp as any) as any);
