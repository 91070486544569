import { combineReducers } from "redux";
import { getRecipesReducer } from "../recipes";
import { GlobalAppState } from "./state";
import { createOrderReducer, getOrderReducer, getOrdersReducer } from "../orders";
import { createPaymentReducer } from "../payment/createPayment";
import { getRecipeReducer } from "../recipes/getRecipe";
import { getAddressesReducer } from "../address/getAddresses";
import { createAddressModalReducer } from "../address/createAddressModal";
import { createAddressReducer } from "../address/createAddress";
import { getCustomerLatestSubscriptionReducer } from "../customer/getCustomerLatestSubscription";
import { deleteAddressReducer } from "../address/deleteAddress";
import { createMealPlanSubscriptionReducer } from "../meal-plan-subscription/createMealPlanSubscription";
import { getMealPlanSubscriptionReducer } from "../meal-plan-subscription/getMealPlanSubscription";
import { otpReducer } from "../otp/reducer";

export const appReducer = combineReducers<GlobalAppState>({
  getRecipes: getRecipesReducer,
  createOrder: createOrderReducer,
  getOrder: getOrderReducer,
  getOrders: getOrdersReducer,
  getAddresses: getAddressesReducer,
  createPayment: createPaymentReducer,
  getRecipe: getRecipeReducer,
  createAddressModal: createAddressModalReducer,
  createAddress: createAddressReducer,
  getCustomerLatestSubscription: getCustomerLatestSubscriptionReducer,
  deleteAddress: deleteAddressReducer,
  createMealPlanSubscription: createMealPlanSubscriptionReducer,
  getMealPlanSubscription: getMealPlanSubscriptionReducer,
  otp: otpReducer
} as any);
