import { all } from "redux-saga/effects";
import { GetRecipesSagas } from "../recipes";
import { CreateOrderSagas, GetOrderSagas, GetOrdersSagas } from "@mealhaus/orders";
import { CreatePaymentSagas } from "../payment/createPayment";
import { GetRecipeSagas } from "../recipes/getRecipe";
import { GetAddressesSagas } from "../address/getAddresses";
import { CreateAddressSagas } from "../address/createAddress";
import { GetCustomerLatestSubscriptionSagas } from "../customer/getCustomerLatestSubscription";
import { DeleteAddressSagas } from "../address/deleteAddress";
import { CreateMealPlanSubscriptionSagas } from "../meal-plan-subscription/createMealPlanSubscription";
import { GetMealPlanSubscriptionSagas } from "../meal-plan-subscription/getMealPlanSubscription";
import { OtpSagas } from "../otp";

export function* AppSaga() {
  yield all([
    ...GetRecipesSagas,
    ...GetRecipeSagas,
    ...CreateOrderSagas,
    ...CreatePaymentSagas,
    ...GetOrderSagas,
    ...GetOrdersSagas,
    ...GetAddressesSagas,
    ...CreateAddressSagas,
    ...GetCustomerLatestSubscriptionSagas,
    ...DeleteAddressSagas,
    ...CreateMealPlanSubscriptionSagas,
    ...GetMealPlanSubscriptionSagas,
    ...OtpSagas
  ]);
}
