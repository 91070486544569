import { applyMiddleware, compose, Store, createStore } from 'redux';
import { ComponentType } from 'react';
import createSagaMiddleware from 'redux-saga';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import { GlobalAppState } from './state';
import { AppSaga } from './sagas';
import { appReducer } from './reducer';

// TODO needed?
export * from './state';

// Configure Redux Dev Tools
let composeEnhancers = compose;
if (
    typeof window !== 'undefined' && process.env.NODE_ENV !== 'production'
    // TODO: and config
   // && (config.publicRuntimeConfig.NODE_ENV === 'local' || config.publicRuntimeConfig.NODE_ENV === 'development')
) {
    composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
}

const sagaMiddleware = createSagaMiddleware();

/**
 * @param {object} initialState
 * @param {boolean} options.isServer indicates whether it is a server side or client side
 * @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
 * @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
 * @param {boolean} options.debug User-defined debug mode param
 * @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
 */
const configureStore = (initialState: GlobalAppState): Store<GlobalAppState> => {
    const store: any = createStore<GlobalAppState,any,any,any>(
        appReducer,
        initialState,
        composeEnhancers(applyMiddleware(sagaMiddleware))
    );

    store.runSagaTask = () => (store.sagaTask = sagaMiddleware.run(AppSaga));

    store.runSagaTask();

    return store;
};

export const connectWithRedux = (component: ComponentType) => withRedux(configureStore)(withReduxSaga(component) as any);
